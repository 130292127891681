.flex-1 {
  flex: 1 1 0%;
  min-width: 0;
}

.flex-2 {
  flex: 2 2 0%;
  min-width: 0;
}

.flex-3 {
  flex: 3 3 0%;
  min-width: 0;
}

.flex-4 {
  flex: 4 4 0%;
  min-width: 0;
}

body {
  background-color: var(--light);
}

.pointer {
  cursor: pointer;
}